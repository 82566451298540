import React from 'react';
import './RegisterInterestBanner.scss';
import { BlackFridayBanner } from 'components/Home/RegisterInterestBanner/BlackFriday';
import { SamsungBanner } from 'components/Home/RegisterInterestBanner/SamsungBanner';
import LaunchDarkly from 'components/Common/LaunchDarkly/LaunchDarkly';
import { Flags } from 'services/LaunchDarkly/flags';

interface BannerProps {
	isBlackFriday?: boolean;
	isSamsungPromo?: boolean;
}

const TRACKING_OBJECT = {
	event: 'merchClick',
	eventCategory: 'Merchandise strip banners',
	eventAction: 'Mid strip banner - Sign up ',
};

export const RegisterInterestBanner = ({
	isBlackFriday = false,
	isSamsungPromo = false,
}: BannerProps): JSX.Element => {
	const trackClick = (): void => {
		globalThis.dataLayer.push(TRACKING_OBJECT);
	};

	if (isBlackFriday) {
		return <BlackFridayBanner onClick={trackClick} />;
	}

	if (isSamsungPromo) {
		return <SamsungBanner onClick={trackClick} />;
	}

	return (
		<LaunchDarkly flag={Flags.HomepageBannerVariant}>
			<LaunchDarkly.Boolean value={true}>
				<a
					href="/register-your-interest/samsung"
					style={{ textAlign: 'center', display: 'block' }}
					onClick={trackClick}
				>
					<img
						src="https://media.mobilephonesdirect.co.uk/images/landing-pages/sign-up/samsung-newsletter-desktop-2024.jpg"
						alt="Sign up to our newsletter for the latest phone releases, exclusive deals, prizes and more"
						className="u-d--none u-d--block--bp-medium"
						style={{ borderRadius: '12px', verticalAlign: 'top' }}
					/>
					<img
						src="https://media.mobilephonesdirect.co.uk/images/landing-pages/sign-up/samsung-newsletter-mobile-2024.jpg"
						alt="Sign up to our newsletter for the latest phone releases, exclusive deals, prizes and more"
						className="u-d--none--bp-medium"
						style={{ borderRadius: '12px', verticalAlign: 'top' }}
					/>
				</a>
			</LaunchDarkly.Boolean>
			<LaunchDarkly.Boolean value={false}>
				<a
					href="/register-your-interest"
					style={{ textAlign: 'center', display: 'block' }}
					onClick={trackClick}
				>
					<img
						src="https://media.mobilephonesdirect.co.uk/images/landing-pages/sign-up/sign-up-banner-bf-desktop.jpg"
						alt="Sign up to our newsletter for the latest phone releases, exclusive deals, prizes and more"
						className="u-d--none u-d--block--bp-medium"
						style={{ borderRadius: '12px', verticalAlign: 'top' }}
					/>
					<img
						src="https://media.mobilephonesdirect.co.uk/images/landing-pages/sign-up/sign-up-banner-bf-mobile.jpg"
						alt="Sign up to our newsletter for the latest phone releases, exclusive deals, prizes and more"
						className="u-d--none--bp-medium"
						style={{ borderRadius: '12px', verticalAlign: 'top' }}
					/>
				</a>
			</LaunchDarkly.Boolean>
		</LaunchDarkly>
	);
};
